<template>
  <div class="chat-wallpaper col-12 col-lg-6 c">
    <div class="card">
      <div
        class="card-header bg-light-success text-center"
        style="border-bottom: solid 5px; padding: 6px"
      >
        <h4 class="c">
          تواصل مع: {{ info[4] }}
          <span v-if="hash"><br />{{ hash }}</span>
          <!-- <br />
          <span style="font-size: 14px" class="g">
            <a
              href="https://smart-whats.com/#from_live_chat"
              class="text-dark"
              target="_blank"
              >الخدمة مقدمة من:
              <strong class="text-success">smart-whats.com</strong></a
            ></span
          > -->
        </h4>
      </div>
      <div class="card-body" style="overflow-y: scroll; padding-bottom: 20px">
        <div v-if="noapikey">
          <br /><br />
          <div class="alert alert-danger text-center">
            <h3>الـ JWT (الرمز) الخاص بك غير صالح.</h3>
          </div>
        </div>
        <div v-if="!noapikey">
          <div v-if="registered">
            <div class="col-12 text-center g" v-if="loading">
              <br /><br /><br />
              <img :src="require('@/assets/images/loading.svg')" /><br />
              جاري تنزيل المعلومات...<br /><br />
              في حالة عدم الاستجابة خلال دقيقة: تأكد من انك قمت بضبط اعدادات
              الواتساب في حسابك وان الجهاز متصل في الموقع.
            </div>
            <template v-for="(msg, index) in messages">
              <div
                :class="
                  'col-12 col-lg-7 g msg ' +
                  (msg.fromme ? 'bg-success text-white right' : 'border left')
                "
                :key="index"
              >
                <div v-if="msg.quotedMsg">
                  <div v-if="msg.quotedMsg.type">
                    <small v-if="msg.quotedMsg.type != 'chat'">
                      <i class="fa fa-arrow-left"></i> رد على:
                      {{ msg.quotedMsg.type }}
                    </small>
                    <small v-if="msg.quotedMsg.type == 'chat'">
                      <i class="fa fa-arrow-left"></i> رد على: "{{
                        msg.quotedMsg.body
                      }}"
                    </small>
                  </div>
                </div>
                <div v-if="msg.isForwarded">
                  <span><i class="fa fa-reply"></i> رسالة محولة</span>
                </div>
                {{ msg.body }}
                <span
                  v-if="msg.hasMedia"
                  v-b-modal.modal-1
                  @click="
                    key = msg.mediaKey;
                    loading2 = true;
                    type = msg.type;
                    messageMedia = {};
                    getMedia();
                  "
                  style="cursor: pointer"
                >
                  <br />
                  <img
                    v-if="
                      msg.type != 'ptt' &&
                      (msg.type == 'document' ||
                        msg.type == 'pdf' ||
                        msg.type == 'image' ||
                        msg.type == 'video' ||
                        msg.type == 'audio')
                    "
                    :src="require('@/assets/images/' + msg.type + '.png')"
                    style="width: 30px"
                  />
                  <img
                    v-if="msg.type == 'ptt'"
                    :src="require('@/assets/images/ppt.png')"
                    style="width: 30px"
                  />
                  تشغيل/عرض
                </span>
                <br />
                <small>{{ msg.time }}</small>
              </div>
            </template>
          </div>
        </div>
        <div
          class="g"
          style="padding-top: 30px"
          v-if="messages.length == 0 && !loading && registered"
        >
          <div class="alert alert-info text-center g">
            <h5>لا يوجد اي محادثات سابقة. قم بارسال رسالة الآن!</h5>
          </div>
        </div>
        <div class="g" style="padding-top: 30px" v-if="!registered">
          <div class="alert alert-danger text-center g">
            <img
              class="g c"
              :src="require('@/assets/images/user-error.png')"
              style="width: 200px"
            />
            <h5>
              عفواً، هذا الرقم <strong>{{ info[4] }}</strong> ليس لديه واتساب<br /><br />
              برجاء التأكد من ان الرقم مكتوب بالصيغة الدولية او ان الرقم لديه
              واتساب بالفعل.
            </h5>
          </div>
        </div>
      </div>
      <div
        class="card-footer"
        style="
          padding: 0px;
          position: fixed;
          bottom: 0px;
          width: 100%;
          right: 0px;
          z-index: 99999;
        "
      >
        <input
          type="text"
          :disabled="readonly"
          placeholder="اكتب هنا..."
          v-model="message"
          v-on:keyup.enter="send"
          class="form-control form-control-lg"
          style="width: 100%; height: 100%; background: #eee"
        />
      </div>
    </div>
    <!-- basic modal -->
    <b-modal id="modal-1" title="تشغيل المحتوى" hide-footer>
      <div class="col-12 text-center">
        <div class="c col-12 text-center" v-if="loading2">
          <img :src="require('@/assets/images/loading.svg')" /><br />
          جاري فك تشفير المحتوى...<br /><small class="text-muted"
            >يتم فك التشفير لأول مرة فقط في الرسالة.</small
          ><br /><br />
        </div>
        <div v-if="!loading2">
          <span v-if="type == 'image'">
            <img
              :src="`data:${messageMedia.mimetype};base64,${messageMedia.data}`"
              style="width: 100%"
            />
          </span>
          <span v-if="type == 'document'">
            <a
              target="_blank"
              class="btn btn-lg btn-success"
              :href="`data:${messageMedia.mimetype};base64,${messageMedia.data}`"
            >
              <i class="fa fa-file"></i>
              عرض الملف</a
            >
          </span>
          <span v-if="type == 'audio' || type == 'ptt'">
            <audio controls>
              <source
                :src="`data:${messageMedia.mimetype};base64,${messageMedia.data}`"
                :type="messageMedia.mimetype"
              />
            </audio>
          </span>
          <span v-if="type == 'video'">
            <video width="320" height="240" controls>
              <source
                :src="`data:${messageMedia.mimetype};base64,${messageMedia.data}`"
                :type="messageMedia.mimetype"
              />
            </video>
          </span>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { BModal, VBModal } from "bootstrap-vue";
export default {
  components: {
    BModal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      loading2: false,
      loading: true,
      info: [],
      messages: [],
      checked: false,
      readonly: false,
      message: "",
      registered: true,
      time: 10,
      key: "",
      type: "",
      messageMedia: {},
      noapikey: false,
      hash: decodeURI(window.location.hash?.replace("#", "")),
    };
  },
  created() {
    this.info = window.location.pathname.split("/");
    this.getMessages();
    var g = this;
    setInterval(() => {
      if (g.time == 0) {
        g.time = 10;
      } else {
        g.time = g.time - 1;
      }
    }, 1000);
  },
  methods: {
    getMedia() {
      var g = this;
      $.post(api + "/user/messages/live-chat-media", {
        key: g.key,
        jwt: g.info[2],
        id: g.info[3],
      })
        .then(function (r) {
          if (r.response) {
            g.loading2 = false;
            g.messageMedia = r.response;
            g.key = null;
          } else {
            setTimeout(() => {
              g.getMedia();
            }, 1000);
          }
        })
        .catch(function () {
          setTimeout(() => {
            g.getMedia();
          }, 1000);
        });
    },
    checkOrder(id) {
      var g = this;
      $.post(api + "/user/contacts/check-registered-order", {
        jwt: g.info[2],
        order_id: id,
      })
        .then(function (r) {
          if (r.response) {
            g.checked = true;
          }
        })
        .catch(function () {
          setTimeout(() => {
            g.checkOrder(id);
          }, 1000);
        });
    },
    getMessages() {
      var g = this;
      $.post(api + "/user/messages/live-chat-update", {
        jwt: g.info[2],
        id: g.info[3],
        key: g.key,
        number: g.info[4],
      }).then(function (r) {
        if (!r.status) {
          r = JSON.parse(r);
        }
        if (r.status == 200) {
          g.noapikey = true;
          return false;
        }
        g.checked = false;
        g.checkOrder(r.response);
        var x = setInterval(() => {
          if (g.checked == false) {
            g.checkOrder(r.response);
          } else {
            clearInterval(x);
            $.post(api + "/user/messages/live-chat", {
              jwt: g.info[2],
              id: g.info[3],
              number: g.info[4],
            })
              .then(function (r) {
                var oldlength = g.messages.length;
                g.messages = JSON.parse(r.response.chat).map((x) => {
                  x.time = new Date(x.timestamp).toLocaleTimeString();
                  return x;
                });
                g.registered = r.response.registered;
                g.time = 10;
                setTimeout(() => {
                  if (oldlength != g.messages.length) {
                    $(".card-body").scrollTop($(".card-body")[0].scrollHeight);
                    if (g.messages[g.messages.length - 1]) {
                      if (
                        !g.loading &&
                        !g.messages[g.messages.length - 1].fromme
                      ) {
                        var audio = new Audio(
                          "https://cdn-static.brmja.com/storage/uploads/files/620511d8d5536534353593.mp3"
                        );
                        audio.play();
                      }
                    }
                  }
                  g.loading = false;
                  g.getMessages();
                }, 100);
              })
              .catch(function () {
                location.reload();
              });
          }
        }, 2000);
      });
    },
    send() {
      var g = this;
      if (g.message?.trim() != "") {
        g.readonly = true;
        $.post(api + "/user/messages/send", {
          jwt: g.info[2],
          devices: g.info[3],
          numbers: g.info[4],
          file: "",
          message: g.message,
        })
          .then(function (r) {
            g.readonly = false;
            g.messages.push({
              fromme: true,
              body: g.message,
              time: "جاري الارسال...",
            });
            g.message = "";
            setTimeout(() => {
              $(".card-body").scrollTop($(".card-body")[0].scrollHeight);
            }, 100);
            g.time = 10;
          })
          .catch(function () {
            alert("فشل الارسال برجاء اعادة المحاولة");
            g.readonly = false;
          });
      }
    },
  },
};
</script>

<style>
.chatbtna_a {
  display: none !important;
}
body {
  background: #333 !important;
  overflow: hidden !important;
}
.chat-wallpaper .card {
  height: 90vh !important;
}
.right {
  float: right;
}
.left {
  float: left;
  background: #ddd !important;
}
.msg {
  border-radius: 10px 10px 20px 20px;
  margin-top: 10px;
}
</style>